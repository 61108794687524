<template>
  <footer
    data-cy-region="footer"
    class="container mx-auto w-full px-6 py-12 lg:py-0 bg-primary-1-100 antialiased rounded-[40px]"
  >
    <div class="FooterLinks container grid gird-cols-1 gap-x-10 gap-y-8 text-white">
      <div>
        <h3 class="text-lg capitalize font-bold">{{ $t('customerService') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-sm">
          <li>
            <AppLink to="/contact">{{ $t('contactUs') }}</AppLink>
          </li>
          <li>
            <AppLink to="/faqs">{{ $t('faqs') }}</AppLink>
          </li>
        </ul>
      </div>

      <div class="[mb-7 lg:m-0 ]">
        <h3 class="text-lg capitalize font-bold">{{ $t('about') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-sm">
          <li>
            <AppLink to="/about">{{ $t('who') }}</AppLink>
          </li>
          <!-- TODO: Show when there are stores -->
          <!-- <li>
            <AppLink to="/stores">{{ $t('stores') }}</AppLink>
          </li> -->
          <!-- <li>
            <AppLink to="/careers">{{ $t('careers') }}</AppLink>
          </li> -->
        </ul>
      </div>

      <div class="[mb-7 lg:m-0 ]">
        <h3 class="text-lg capitalize font-bold">{{ $t('more') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-sm">
          <li>
            <AppLink to="/privacy-policy">{{ $t('privacyPolicy') }}</AppLink>
          </li>

          <li>
            <AppLink to="/terms-and-conditions">{{ $t('termsAndConditions') }}</AppLink>
          </li>
        </ul>
      </div>

      <div class="md:hidden flex flex-col gap-x-2.5 [ lg:mx-6 pb-10 ] [ border-b border-gray-300 ]">
        <h3 class="text-base capitalize font-bold">{{ $t('changeLang') }}</h3>
        <a
          :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-bold' : 'font-body']"
          >{{ $t('switchLang') }}</a
        >
      </div>

      <div class="[ lg:mx-0 pb-10 lg:pb-0 ] [ border-b sm:border-0 border-gray-300 ] overflow-hidden">
        <h3 class="text-lg capitalize font-bold mb-3">{{ $t('partnerBrand') }}</h3>
        <a target="_blank" aria-label="navigate to mazaya" href="https://www.mazaya.eg/en/">
          <span class="hidden"> navigate to mazaya </span>
          <svg-icon-mazaya-logo class="w-22 h-7" />
        </a>
      </div>

      <div class="[ lg:mx-6 md:mx-0 pb-8 lg:pb-0 ] [ border-b sm:border-0 border-gray-300 ] md:w-75 z-0">
        <h3 class="text-lg capitalize font-bold">{{ $t('newsletter') }}</h3>
        <div class="[ flex flex-col justify-center ] relative md:mt-2">
          <form class="NewsletterForm md:mt-2" @submit.prevent="onSubmit">
            <TextInput
              id="newsletter_email"
              v-model="email"
              name="email"
              :label="$t('subscribe')"
              class="text-base font-medium h-12 placeholder-primary-1-20"
              type="email"
              :placeholder="$t('addYourEmail')"
              aria-label="Email Address"
              rules="email"
              :filled="false"
              border
              rounded
              dark
            />
            <button
              class="w-5 h-5 absolute right-2 bottom-3 z-10 [ flex items-center justify-center ]"
              :loading="isSubmitting"
              variant="outline"
              :disabled="!meta.valid"
              :aria-label="$t('send')"
              disable-hover
            >
              <ClientOnly>
                <svg-icon name="send" class="w-6 h-6" />
              </ClientOnly>
            </button>
          </form>
        </div>
      </div>

      <!-- TODO: enable app store icons when the apps are avaliable -->

      <div class="md:flex md:col-span-2 [ lg:mx-0 py-10 lg:py-0 ] [ border-b sm:border-0 border-gray-300 ]">
        <!-- <ul class="flex items-center justify-between sm:gap-8 xl:gap-17">
          <li class="flex items-center space-x-2">
            <svg-icon name="get-apple-app" width="132" height="36" />
          </li>

          <li class="flex items-center space-x-2">
            <svg-icon name="Google_Play-Badge" width="132" height="36" />
          </li>
        </ul> -->
      </div>

      <div class="md:col-span-2 [ lg:mx-0 pb-8 lg:pb-0 ][ flex items-center justify-center md:justify-start ]">
        <ul class="grid grid-cols-2 gap-x-12 items-center">
          <li>
            <a href="https://www.facebook.com/karacaegypt" aria-label="Like us on Facebook">
              <svg-icon-facebook width="30" height="30" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/karacaegypt" aria-label="Follow us on Instagram">
              <svg-icon-instagram width="30" height="30" />
            </a>
          </li>
        </ul>
      </div>

      <div class="hidden md:flex items-center justify-end self-end gap-x-2.5">
        <h3 class="text-base capitalize font-bold font-body">{{ $t('changeLang') }}</h3>
        <a
          :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold -mt-1.5' : 'font-body']"
          >{{ $t('switchLang') }}</a
        >
      </div>
    </div>
    <div class="hidden md:flex h-unit bg-primary-1-80 md:col-span-5 lg:mx-6 md:mx-0 my-8" />

    <div class="w-full mx-auto text-center" dir="ltr">
      <p class="md:text-center capitalize text-white mb-16 md:mb-8">
        ©{{ new Date().getFullYear() }} - {{ $t('appName') }} | {{ $t('copyrights') }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
defineComponent({
  name: 'TheFooterMainSection',
});
const switchLocalePath = useSwitchLocalePath();
const route = useRoute();

// To keep query params if exists
function switchLocalePathWithParams(locale: string) {
  const queryParams = route.fullPath.split('?')[1] ? `?${route.fullPath.split('?')[1]}` : '';
  return `${switchLocalePath(locale)}${queryParams}`;
}

const { t: $t } = useI18n({ useScope: 'local' });

import { CombinedError } from 'villus';
import { object, string } from 'yup';

const { submitForm } = useSubscribeToNewsletter();

const { success, error } = useAlerts();

const { meta, handleSubmit, defineField, setErrors, resetForm, isSubmitting } = useForm({
  validationSchema: object({
    email: string().email(),
  }),
});

const [email] = defineField('email');
const onSubmit = handleSubmit(async ({ email }) => {
  try {
    await submitForm(email);
    success($t('success').toString(), $t('newsLetterSuccess').toString());
    resetForm();
  } catch (e) {
    if (e instanceof CombinedError) {
      error('error', e.message);
      setErrors({
        email: e.message.toString(),
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
footer {
  /* @screen sm {
    height: 100%;
  } */

  .FooterLinks {
    /* margin: 74px 24px; */
    @screen lg {
      grid-template-columns: 1fr 1fr 1fr 1fr 360px;

      margin: 80px auto 0 auto;
    }

    a {
      @apply relative;
    }

    & > div:not(:last-child) {
      @apply relative;
    }
    & > div:not(:last-child)::after {
      @apply mx-auto;
      @screen md {
        content: unset;
      }
      height: 1px;
      width: calc(100% - 50px);
      background-color: #d0dedc;
      position: absolute;
      bottom: -24px;
      right: 0;
      left: 0;
    }
  }
}
.NewsletterForm {
  .AppButton {
    padding: 0;
    border: none;
    z-index: 3;
  }
}
</style>

<i18n>
{
  "en": {
    "customerService": "Customer Service",
    "faqs": "FAQs",
    "contactUs": "Contact us",
    "about": "About Karaca",
    "who": "Who are we?",
    "stores": "Find Stores",
    "more": "More",
    "refunds": "Returns & Refund",
    "termsAndConditions": "Terms & Conditions",
    "subscribe": "Subscribe to our newsletter",
    "follow": "Follow us",
    "copyrights": "All Rights Reserved",
    "changeLang": "Change Language",
    "switchLang": "عربي",
    "downloadApp": "Download App",
    "weAccept": "We Accept",
    "careers": "Careers",
    "privacyPolicy": "Privacy Policy",
    "newsletter": "Newsletter",
    "beTheFirstToKnow": "BE THE FIRST TO KNOW",
    "note": "Sign up to newsletter to get latest updates about new products and exclusive discounts",
    "email": "E-mail Address",
    "addYourEmail": "example{'@'}gmail.com",
    "partnerBrand": "Partners",
    "success": "Success",
    "newsLetterSuccess": "Subscribed to newsletter successfully!",
    "error": "Subscribe Error",
    "returns": "Refunds & Returns ",
    "appName": "Karaca",
    "send": "Send"
  },
  "ar": {
    "customerService": "خدمة العملاء",
    "faqs": "الأسئلة الشائعة",
    "contactUs": "اتصل بنا",
    "about": "عن الشركة",
    "who": "من نحن؟",
    "stores": "الفروع",
    "more": "المزيد",
    "refunds": "الاسترجاع",
    "termsAndConditions": "سياسة الاستخدام",
    "subscribe": "سجل للنشرة",
    "follow": "تابعنا",
    "copyrights": "جميع الحقوق محفوظة",
    "switchLang": "English",
    "downloadApp": "تحميل التطبيق",
    "weAccept": "نحن نقبل",
    "careers": "المهن",
    "privacyPolicy": "سياسة الخصوصية",
    "newsletter": "- النشرة الإخبارية",
    "beTheFirstToKnow": "كن أول من يعرف",
    "note": "اشترك بنشرتنا الإخبارية لتصلك أجدد المنتجات و عروض خاصة",
    "email": "العنوان الالكتروني",
    "addYourEmail": "example{'@'}gmail.com",
    "changeLang": "تغيير اللغه",
    "partnerBrand": "العلامة التجارية الشريكة",
    "success": "نجاح",
    "newsLetterSuccess": "تم التسجيل بنجاح!",
    "error": "خطأ في الاشتراك",
    "returns": "الاسترجاع",
    "appName": "كاراجا",
    "send": "ارسال"
  }
}
</i18n>
